<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-end">
      <BaseHeader :title="$t('views.buyer.my_purchases.text_3016')" />
    </div>

    <div>
      <div v-if="total" class="text3 d-flex" :class="{'custom-mb': !isMobile, 'justify-content-end': !isMobile}">
        {{ total }} {{ $t('views.buyer.my_purchases.text_3050') }}
      </div>
      <b-tabs
        v-model="tabIndex"
        nav-class="TabHeader"
        :active-nav-item-class="isHeaven ? 'TabHeader-heaven--active' : 'TabHeader--active'"
        :no-nav-style="true"
        @activate-tab="() => {}"
      >
        <b-tab :title="$t('views.buyer.my_purchases.text_3017')">
          <MyPurchasesTable
            ref="myPurchasesTable"
            v-if="tabIndex === 0"
            :tab-index="tabIndex"
            @update-total="totalPurchases"
          />
        </b-tab>
        <b-tab :title="$t('views.buyer.my_purchases.text_3018')">
          <MyPurchasesSubscriptionTable
            ref="myPurchasesSubscriptionTable"
            v-if="tabIndex === 1"
            :tab-index="tabIndex"
            @update-total="totalPurchases"
          />
        </b-tab>
      </b-tabs>
    </div>
    
  </div>
</template>

<script>
import MyPurchasesTable from '@/components/Buyer/purchases/MyPurchasesTable.vue'
import MyPurchasesSubscriptionTable from '@/components/Buyer/purchases/MyPurchasesSubscriptionTable.vue'

export default {
  name: "MyPurchases",
  components: { MyPurchasesTable, MyPurchasesSubscriptionTable },
  data() {
    return {
      tabIndex: 0,
      client: { width: 0 },
      total: 0
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    totalPurchases(total) {
      this.total = total
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize)
  }
}
</script>

<style scoped>
.text3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #81858E;
}
.custom-mb {
  margin-bottom: -36px;
} 
</style>
